var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "container-404" }, [
    _c("div", { staticClass: "pic-404" }, [
      _c("img", {
        staticClass: "pic-404__parent",
        attrs: { src: _vm.img_404, alt: "404" },
      }),
      _c("img", {
        staticClass: "pic-404__child left",
        attrs: { src: _vm.img_404_cloud, alt: "404" },
      }),
      _c("img", {
        staticClass: "pic-404__child mid",
        attrs: { src: _vm.img_404_cloud, alt: "404" },
      }),
      _c("img", {
        staticClass: "pic-404__child right",
        attrs: { src: _vm.img_404_cloud, alt: "404" },
      }),
    ]),
    _vm._m(0),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "bullshit" }, [
      _c("div", { staticClass: "bullshit__oops" }, [_vm._v("OOPS!")]),
      _c("div", { staticClass: "bullshit__info" }, [
        _vm._v(" The page your are looking for was not found. "),
      ]),
      _c("a", { staticClass: "bullshit__return-home", attrs: { href: "/" } }, [
        _vm._v("Home"),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }